import { getBaseApiHelper } from '../../common/utils/apiHelpers'

export const CONSTANTS_REQUEST = 'CONSTANTS_REQUEST'
export const CONSTANTS_SUCCESS = 'CONSTANTS_SUCCESS'
export const CONSTANTS_FAILURE = 'CONSTANTS_FAILURE'


// CONSTANTS API INCLUDES CROPS AND INDICATORS
// Constants dispatched actions triggers crops and indicator reducers
function constantsRequest() {
  return {
    type: CONSTANTS_REQUEST,
    isFetching: true,
  }
}

function constantsSuccess(constants) {
  ['FALLOW', 'OTHER', 'COVER CROP'].forEach(key=>{
    constants.crop_pathogens[key] = {}
    constants.crop_pathogens[key].pathogens = {}
  })
  return {
    type: CONSTANTS_SUCCESS,
    constants: constants,
  }
}

function constantsFailure(error) {
  return {
    type: CONSTANTS_FAILURE,
    error,
  }
}

// Thunks/Async Action Creators
export function getConstants() {
  // called on app load once and only once, all accts
  return dispatch => {
    const accountId = JSON.parse(sessionStorage.getItem('accountId'))
    dispatch(constantsRequest())
    return getBaseApiHelper(`/constants/v2?account_id=${accountId}`)
      .then(response => {
        dispatch(constantsSuccess(response.data))
      })
      .catch(rejected => dispatch(constantsFailure(rejected)))
  }
}
