
export const calculateSize = (path) => {
  const conversionConstant = 4046.85642 // square meter to acres
  return (window.google.maps.geometry.spherical.computeArea(path) / conversionConstant).toFixed(2)
}

export const encodePath = (path) => {
  return window.google.maps.geometry.encoding.encodePath(path)
}

export const getFieldCenter = (field) => {
  if (!field.centroid) return
  let [lng, lat] = field.centroid
  return { lat, lng }
}
export const fitMapToFields = (fields, map, center) => {
  if (!fields.some(field => field.centroid) && center) {
    recenterMap(center, map)
    return
  }
  if (map?.fitBounds && fields) {
    const bounds = new window.google.maps.LatLngBounds()
    fields.forEach((field) => {
      if (field.feature) {
        fitMapToFeature(field.feature, bounds)
      }
    })
    map.fitBounds(bounds, { left: 330, bottom: 200 })
  }
  return
}

export const fitMapToFeature = (feature, bounds = null, map = null) => {
  if (!bounds) {
    bounds = new window.google.maps.LatLngBounds()
  }
  let geometry = feature.getGeometry()
  if (geometry) {
    geometry.forEachLatLng(latlng => bounds.extend(latlng))
  } else if (feature.placeholder) {
    bounds.extend(feature.placeholder.center)
  }
  if (map) {
    map.fitBounds(bounds)
  }
  return bounds
}

export const addEventsToShapes = (shape, func) => {
  shape.addListener('dragend', () => {
    func(shape)
  })
  // shape.addListener('click', () => {
  //   func(shape);
  // });
  shape.addListener('mouseup', () => {
    func(shape)
  })
  shape.getPath().addListener('insert_at', () => {
    func(shape)
  })
  shape.getPath().addListener('set_at', () => {
    func(shape)
  })
  shape.getPath().addListener('remove_at', () => {
    func(shape)
  })
}


//clear map used for drawn shapes
export const clearMap = (shapes) => {
  if (shapes.length === 0) return
  shapes.forEach(shape => {
    shape.setMap(null)
  })
}

export const recenterMap = (data, map) => {
  if (map) {
    const { maps } = window.google
    let center = new maps.LatLng(data.lat, data.lng)
    map.panTo(center)
  }
}

const latLng2point = (latLng) => {
  return {
    x: (latLng.lng + 180) * (256 / 360),
    y: (256 / 2) - (256 * Math.log(Math.tan((Math.PI / 4) + ((latLng.lat * Math.PI / 180) / 2))) / (2 * Math.PI))
  }
}

/**
 * converts boundaries of polygons to svg paths
 * @param {array} gmPaths
 */
const polygonToSVG = (gmMultiPolys) => {
  let minX = 256
  let minY = 256
  let maxX = 0
  let maxY = 0
  let polys = []
  gmMultiPolys.forEach(poly => {
    poly.forEach(gmPaths => {
      let path = []
      let point
      for (var pp = 0; pp < gmPaths.length; ++pp) {
        point = latLng2point(gmPaths[pp])
        minX = Math.min(minX, point.x)
        minY = Math.min(minY, point.y)
        maxX = Math.max(maxX, point.x)
        maxY = Math.max(maxY, point.y)
        path.push([point.x, point.y])
      }
      polys.push(path)
    })
  })
  // must scale difference in order to avoid rounding errors
  polys = polys.map(path => path.map(coords => [((coords[0] - minX) * 1000).toFixed(5), ((coords[1] - minY) * 1000).toFixed(5)]))
  //to path string
  polys = polys.map(path => `M ${path.map(coords => `${coords[0]} ${coords[1]}`).join(' L ')} z`)
  return {
    path: polys.join(' '),
    x: 0,
    y: 0,
    width: (maxX - minX) * 1000,
    height: (maxY - minY) * 1000
  }
}

export const createFieldDataLayer = (fields, map, layer, handlers = {}) => {
  let dataLayer = (!layer || map !== layer.getMap()) ? new window.google.maps.Data({ map: map }) : layer
  dataLayer && dataLayer.forEach(feature => {
    dataLayer.remove(feature)
    feature.placeholder && feature.placeholder.setMap(null)
    if (feature.placeholderEvents) {
      feature.placeholderEvents.forEach(evt => window.google.maps.event.removeListener(evt))
    }
  })
  fields.forEach(field => {
    if (!field.feature) return

    if (field.feature.placeholderEvents) {
      field.feature.placeholderEvents.forEach(evt => window.google.maps.event.removeListener(evt))
    }
    dataLayer.add(
      field.feature
    )
    if (field.feature.placeholder) {
      field.feature.placeholder.setMap(map)
    }
  })
  addHandlersToDataLayer(dataLayer, handlers)
  return dataLayer
}

export const addHandlersToDataLayer = (layer, handlers) => {

  for (let handlerName in handlers) {
    layer.addListener(handlerName, handlers[handlerName])
    layer.forEach(feature => {
      if (feature.placeholder) {
        let listener = feature.placeholder.addListener(handlerName, (evt) => {
          evt.feature = feature
          handlers[handlerName](evt)
        })
        feature.placeholderEvents.push(listener)
      }
    })
  }
}

export const MultiPolygonFeatureToLatLngs = (multiPolyFeature) => multiPolyFeature.getGeometry()?.getArray().map(polyFeature => polyFeature.getArray().map(linearRings => linearRings.getArray()))


export const decodeFieldPaths = (fields) => {
  let dummyLayer = new window.google.maps.Data()
  const fieldsWithFeatures = fields.map((field) => {
    let feature = dummyLayer.addGeoJson(
      {
        'type': 'Feature',
        'id': field.id,
        'geometry': field.boundary
      }
    )
    field.feature = feature[0]
    if (field.boundary) {
      let latlng = []
      let latlngArray = MultiPolygonFeatureToLatLngs(field.feature)
      latlng = latlngArray.map(poly => poly.map((path) => path.map(pt =>
        ({
          lat: pt.lat(),
          lng: pt.lng()
        }))
      ))
      const result = polygonToSVG(latlng)
      field.svg = result
      field.size = latlngArray.reduce(
        (memo, polygon) => memo + polygon.reduce(
          (memo, path, idx) => memo += (calculateSize(path) * (!idx ? 1 : -1)), 0)
        , 0).toFixed(1)
    } else if (field.centroid) {
      field.feature.placeholder = new window.google.maps.Circle({
        center: getFieldCenter(field),
        radius: 100
      })
      field.feature.placeholderEvents = []
    }
    return field
  })
  return fieldsWithFeatures
}

export const featureToBEGeojson = feature => feature.toGeoJson()?.geometry
export const PolyArrayToLatLngArray = (polyArray) => polyArray.map(poly => poly.getPaths().getArray().map(ln => ln.getArray()))
export const polygonsToDataFeature = (polyArray) => (
  new window.google.maps.Data.Feature(
    { geometry: new window.google.maps.Data.MultiPolygon(PolyArrayToLatLngArray(polyArray)) }
  )
)
