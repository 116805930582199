import {
  SELECT_FIELD_FOR_DETAIL,
  GET_ACCOUNT_FIELDS_REQUEST ,
  GET_ACCOUNT_FIELDS_FAILURE,
  GET_ACCOUNT_FIELDS_SUCCESS,

  GET_FIELD_REQUEST ,
  GET_FIELD_FAILURE,
  GET_FIELD_SUCCESS,

  FIELD_POST_REQUEST,
  FIELD_POST_SUCCESS,
  FIELD_POST_FAILURE,

  FIELDS_POST_REQUEST,
  FIELDS_POST_SUCCESS,
  FIELDS_POST_FAILURE,

  ZONE_POST_SUCCESS,

  FIELD_PATCH_REQUEST,
  FIELD_PATCH_SUCCESS,
  FIELD_PATCH_FAILURE,

  DELETE_FIELD_REQUEST,
  DELETE_FIELD_SUCCESS,
  DELETE_FIELD_FAILURE,

  GET_FIELD_MAP_REQUEST,
  GET_FIELD_MAP_SUCCESS,
  GET_FIELD_MAP_FAILURE,

  MERGE_FIELDS_REQUEST,
  MERGE_FIELDS_SUCCESS,
  MERGE_FIELDS_FAILURE,

  GET_ORDER_LIST_FILTERS_REQUEST,
  GET_ORDER_LIST_FILTERS_SUCCESS,
  GET_ORDER_LIST_FILTERS_FAILURE,

  SET_ALL_FIELDS_RETRIEVED
} from './fieldActions'
import { decodeFieldPaths} from '../../common/utils/geoHelpers'
import { createSelector } from 'reselect'
import { getAccountId } from '../utils/apiHelpers'
import { getSelectedAccountNamesById } from '../../components/auth/auth-reducer'
import { appStore } from '../../index'

const initialState = {
  pending: false,
  pendingAccounts: new Set(),
  hasError: false,
  error: null,
  fieldEntities: null,
  selectedFieldId: '',
  isFetchingMap: false,
  isMerging: false,
  mapData: {},
  allFieldsRetrieved: false
}

export const fieldsReducer = (state = { ...initialState }, action) => {

  // This is a special case where we are fetching fields for the order list filters
  const PENDING_ACCOUNT_FOR_FILTERS = 'order-list-filters'


  switch(action.type) {

    ////////////////////////
    // Get Handlers
    ////////////////////////
    case GET_ACCOUNT_FIELDS_REQUEST: {
      return { ...state, pending: true, pendingAccounts: state.pendingAccounts.add(action.accountId) }
    }
    case GET_ACCOUNT_FIELDS_SUCCESS: {
      state.pendingAccounts.delete(action.accountId)
      const fieldEntities = {}
      const payloadWithSVG = decodeFieldPaths(action.payload)
      const {account_id, account_name} = appStore.getState().auth.user
      const growersById = { ...getSelectedAccountNamesById(), [account_id]: account_name }
      payloadWithSVG.forEach(entity => {
        fieldEntities[entity.id] = { ...entity, grower: growersById?.[entity.account_id] }
      })
      return {
        ...state,
        pending: !!state.pendingAccounts.size,
        hasError: false,
        fieldEntities: {
          ...state.fieldEntities,
          [action.accountId]: fieldEntities
        }
      }
    }
    case  GET_ACCOUNT_FIELDS_FAILURE: {
      state.pendingAccounts.delete(action.accountId)
      return {
        ...state,
        pending: !!state.pendingAccounts.size,
        hasError: true,
        error: action.error
      }
    }
    case GET_FIELD_REQUEST: {
      return { ...state, pending: action.isFetching }
    }
    case GET_FIELD_SUCCESS: {
      const acct_id = action.payload.account_id
      const fieldEntities = {...state.fieldEntities}
      const acctFields = {...fieldEntities[acct_id]}
      const payloadWithSVG = decodeFieldPaths([action.payload])[0]
      acctFields[action.payload.id] = payloadWithSVG
      fieldEntities[acct_id] = acctFields
      return {
        ...state,
        pending: false,
        hasError: false,
        fieldEntities: fieldEntities
      }
    }
    case  GET_FIELD_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }



    case SELECT_FIELD_FOR_DETAIL: {
      return {
        ...state,
        selectedFieldId: action.fieldId
      }
    }

    case SET_ALL_FIELDS_RETRIEVED: {
      return { ...state, allFieldsRetrieved: true }
    }

    ////////////////////////
    // Create/Update Field Handlers
    ////////////////////////
    case FIELD_POST_REQUEST: {
      return { ...state, pending: action.isFetching }
    }
    case FIELD_POST_SUCCESS: {
      const fieldEntities = { ...state.fieldEntities }
      const accountId = action.payload.account_id
      fieldEntities[accountId] = {
        ...fieldEntities[accountId],
        [action.payload.id]: {...(decodeFieldPaths([action.payload]))[0], grower: getSelectedAccountNamesById()?.[accountId]}
      }

      return {
        ...state,
        pending: false,
        hasError: false,
        fieldEntities
      }
    }
    case FIELD_POST_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }

    case FIELDS_POST_REQUEST: {
      return { ...state, pending: action.isFetching }
    }
    case FIELDS_POST_SUCCESS: {
      const fieldEntities = { ...state.fieldEntities }
      const accountId = action.payload[0].account_id
      if (!fieldEntities[accountId]) {
        fieldEntities[accountId] = {}
      }
      const accountFields = fieldEntities[accountId]
      action.payload.forEach(field => {
        accountFields[field.id] = {
          ...field,
          grower: getSelectedAccountNamesById()?.[accountId]
        }
      })

      return {
        ...state,
        fieldEntities,
        hasError: false,
        pending: false
      }
    }
    case FIELDS_POST_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }

    case FIELD_PATCH_REQUEST: {
      return { ...state, pending: action.isFetching }
    }
    case FIELD_PATCH_SUCCESS: {
      const fieldEntities = { ...state.fieldEntities }
      const accountId = action.payload.account_id
      fieldEntities[accountId] = {
        ...fieldEntities[accountId],
        [action.payload.id]: {...(decodeFieldPaths([action.payload]))[0], grower: getSelectedAccountNamesById()?.[accountId]}
      }
      return {
        ...state,
        pending: false,
        hasError: false,
        fieldEntities
      }
    }

    case FIELD_PATCH_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }

    case ZONE_POST_SUCCESS: {
      const accountId = action.accountId
      const acctFields = { ...state.fieldEntities[accountId] }
      const field = acctFields[action.fieldId]
      field.zones = action.payload
      return {
        ...state,
        pending: false,
        hasError: false,
        fieldEntities:{
          ...state.fieldEntities,
          [accountId]: acctFields
        }
      }
    }

    ////////////////////////
    // Delete Handlers
    ////////////////////////
    case DELETE_FIELD_REQUEST: {
      return { ...state, pending: action.isFetching }
    }
    case DELETE_FIELD_SUCCESS: {
      const { fieldId, accountId } = action
      const fieldEntities = { ...state.fieldEntities }
      fieldEntities[accountId] = {
        ...fieldEntities[accountId]
      }
      delete fieldEntities[accountId]?.[fieldId]

      return {
        ...state,
        pending: false,
        hasError: false,
        fieldEntities: fieldEntities
      }
    }

    case DELETE_FIELD_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }
    ////////////////////////
    // Get Map Handlers
    ////////////////////////

    case GET_FIELD_MAP_REQUEST: {
      return { ...state, isFetchingMap: true }
    }

    case GET_FIELD_MAP_SUCCESS: {
      const { fieldId, key, payload} = action
      let fieldMaps =  {...state.mapData[fieldId]}
      fieldMaps[key] = payload.data
      return {
        ...state,
        isFetchingMap: false,
        mapData: {...state.mapData, [fieldId]: fieldMaps},
        hasError: false,
        error: null
      }
    }

    case GET_FIELD_MAP_FAILURE: {
      return {
        ...state,
        isFetchingMap: false,
        hasError: true,
        error: action.error
      }
    }

    ////////////////////////
    // Merge Field Handlers
    ////////////////////////

    case MERGE_FIELDS_REQUEST: {
      return { ...state, isMerging: true }
    }

    case MERGE_FIELDS_SUCCESS: {
      return {
        ...state,
        isMerging: false,
        error: null
      }
    }

    case MERGE_FIELDS_FAILURE: {
      return {
        ...state,
        isMerging: false,
        error: action.error
      }
    }


    case GET_ORDER_LIST_FILTERS_REQUEST: {
      return { ...state, pending: true, pendingAccounts: state.pendingAccounts.add(PENDING_ACCOUNT_FOR_FILTERS) }
    }
    case GET_ORDER_LIST_FILTERS_SUCCESS: {
      const fieldEntities = {}
      state.pendingAccounts.delete(PENDING_ACCOUNT_FOR_FILTERS)
      action.payload.forEach(field => {
        fieldEntities[field.account_id] = fieldEntities[field.account_id] ?? {}
        fieldEntities[field.account_id][field.id] = {
          farm: field.farm.name,
          farm_id: field.farm.id,
          name: field.name,
          id: field.id,
          grower: field.account.name
        }
      })
      return {
        ...state,
        pending: !!state.pendingAccounts.size,
        hasError: false,
        fieldEntities: fieldEntities
      }
    }
    case GET_ORDER_LIST_FILTERS_FAILURE: {
      return {
        ...state,
        pending: false,
        hasError: true,
        error: action.error
      }
    }

    default: {
      return state
    }
  }
}


//////////////////
// SELECTORS
//////////////////
const getSelectedAccountId = state => state.auth.selectedAccounts?.[0] || getAccountId()
const getSelectedAccountIds = state => state.auth.selectedAccounts
const getFieldsByAccount = state => state.entities.fields.fieldEntities
const getAccountFields = (fields, acct) => {
  acct = acct || getAccountId()
  let allfields =  {...fields}
  return allfields[acct]
}

const getSelectedAccountFields = (fields, accts) => accts.reduce((acc, acctId) => ({ ...acc, ...fields?.[acctId] }), {})

export const getOwnFields = createSelector(
  getFieldsByAccount,
  getAccountFields
)
export const getFieldsForSelectedAccount = createSelector(
  getFieldsByAccount,
  getSelectedAccountId,
  getAccountFields
)
export const getFieldsForSelectedAccounts = createSelector(
  getFieldsByAccount,
  getSelectedAccountIds,
  getSelectedAccountFields
)

const getSelectedFieldId = state => state.mapSettings.selectedField
const getFieldObj = (acctFields, fieldId) => {
  if (acctFields){
    return acctFields[fieldId]
  }
  return undefined
}

export const getSelectedFieldDetails = createSelector(
  getFieldsForSelectedAccounts,
  getSelectedFieldId,
  getFieldObj
)
